import { useState, useEffect } from "react";
import "./App.css";
import { useNavigate } from "react-router-dom";
import SignInPage from "./components/MainPage";
// import PrivasyPolicy from "./components/Privasy_policy"
// import LandingPage from "./components/LandingPage"

function App() {
  const navigate = useNavigate();
  const handleLinkClick = () => {
    navigate("/identify?token=99898989988");
  };

  const parseTokenFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("token");
  };
  //  set a cookie with the token value
  const setTokenCookie = (token) => {
    document.cookie = `token=${token}; path=/`;
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const token = parseTokenFromURL(currentUrl);
    if (token) {
      setTokenCookie(token);
      console.log("Token stored in cookie:", token);
    } else {
      console.error("No token found in the URL.");
    }
  }, []);

  //Read Tocken
  const [token, setToken] = useState("");

  useEffect(() => {
    const parseTokenFromCookie = () => {
      const cookies = document.cookie.split(";");
      for (let cookie of cookies) {
        const [name, value] = cookie.trim().split("=");
        if (name === "token") {
          return value;
        }
      }
      return "";
    };

    const tokenValue = parseTokenFromCookie();
    setToken(tokenValue);
  }, []);

  return (
    <div className="App">
      <SignInPage />
    </div>
  );
}

export default App;
