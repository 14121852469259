import { useState, useEffect, useCallback, useRef } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./LandingPage.css";
import bumppylogo from "../images/B logo.svg";
import { Form, FormControl } from "react-bootstrap";
import logo from "../images/Bumppy_Logo_Normal.svg";
import payment from "../images/Payment.svg";
import food from "../images/fOOD.svg";
import travel from "../images/travel.svg";
import edtech from "../images/Ed-Tech.svg";
import ecom from "../images/E- commerce.svg";
import "./SignInPage.css";

import "react-phone-input-2/lib/style.css";
import "./LandingPage.css";
import Profile from "../images/Profile.svg";
import { auth } from "../utils/firebase/firebase.config.js";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const useLocation = () => {
  // const [location, setLocation] = useState(null);

  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLocation = async () => {
      if (!navigator.geolocation) {
        setError("Geolocation is not supported by your browser");
        return;
      }

      try {
        const position = await getCurrentPosition();
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude });
      } catch (error) {
        // Handle error when user denies location permission
        if (error.code === 1) {
          // showLocationAccessDeniedAlert();
        } else {
          setError("Error fetching location.");
        }
      }
    };

    // fetchLocation();
  }, []);

  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  // const showLocationAccessDeniedAlert = () => {
  //   Swal.fire({
  //     icon: 'error',
  //     title: 'Location Access Denied',
  //     text: 'Without location access, you cannot proceed with login.',
  //     confirmButtonText: 'OK'
  //   });
  // };

  return { location, error };
};

const SignInPage = () => {
  const urlParams2 = new URLSearchParams(window.location.search);
  const productIdGlobal = urlParams2.get("product_id");
  const intent = urlParams2.get("intent");
  const { location, error } = useLocation();
  const [loginPhone, setLoginPhone] = useState("");
  const [loginPass, setLoginPass] = useState("");
  const [accessToken, setAccessToken] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [accountFound, setAccountFound] = useState(false);
  const [wrongCred, setWrongCred] = useState(false);
  const [userSearchResult, setUserSearchResult] = useState(null);
  const [emailSection, setEmailSection] = useState(true);
  const [passwordSection, setPasswordSection] = useState(false);
  const [createaccountSection, setCreateaccountSection] = useState(false);
  const [createPasswordSection, setCreatePasswordSection] = useState(false);
  const [forgotPasswordSection, setForgotPasswordSection] = useState(false);
  const [otpSection, setOtpSection] = useState(false);
  const [realOtp, setRealOtp] = useState("");
  const [forgetPassword_RealOtp, setForgetPassword_RealOtp] = useState("");
  const [contry, setCountry] = useState("+91");
  const [forgetPass_realOtp, setForgetPass_RealOtp] = useState("");
  const [createAccountOtpSection, setCreateAccountOtpSection] = useState(false);
  const [create_forgotPasswordSection, setCreate_forgotPasswordSection] =
    useState(false);
  const [newPassword_ForgetPassword, setNewPassword_ForgetPassword] =
    useState("");

  console.log(auth);

  const [showPassword3, setShowPassword3] = useState(false);

  const [newUsername, setNewUsername] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [newFirstName, setFirstName] = useState("");
  const [newLastName, setLastName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newDob, setDob] = useState("");
  const [newGender, setGender] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const [usernameAPI, setUserNameAPI] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [newDeviceOtpSection, setNewDeviceOtpSection] = useState(false);

  const [errors, setErrors] = useState({
    contry: "",
    loginPhone: "",
    loginPass: "",
    newUsername: "",
    newpassword: "",
    newConfirmPassword: "",
    newFirstName: "",
    newLastName: "",
    newDobday: "",
    newDobmonths: "",
    newDobyear: "",
    newGender: "",
    newRealOtp: "",
    newEmail: "",
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization:
      "Basic QlVNUFBZX1NTT19TZ0xqdkFSSjVMYlg6dEdoQWxQSEVreDFNY0Jua3c0M3ZYVVhzNDRpNzQ0alFiYmpVMU9ESEI1U0lSdFNERllhWUYxeWVHRkpCWWxacQ==",
  };
  const createPasswordSectionhandle = () => {
    if (newFirstName === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newFirstName: "First name is required", // Set error message for newFirstName field
      }));
      return;
    }
    if (newLastName === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newLastName: "Last name is required", // Set error message for newFirstName field
      }));
      return;
    }

    if (newUsername.length !== 10 || newUsername === "") {
      if (newEmail) {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            newEmail: "Invalid Email",
          }));
          return;
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          newUsername: "10 digits Mobile Number required.",
        }));
        return;
      }
    }
    if (day === "" || day > 31) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newDobday: " required", // Set error message for newFirstName field
      }));
      return;
    }
    if (month === "" || month > 12) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newDobmonths: "required", // Set error message for newFirstName field
      }));
      return;
    }
    if (year === "" || year > 2024) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newDobyear: "required", // Set error message for newFirstName field
      }));
      return;
    }
    if (userData.gender === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newGender: "required", // Set error message for newFirstName field
      }));
      return;
    }

    closeallhandle();
    setCreatePasswordSection(true);
  };

  const handleRegisterPasswordSection = () => {
    if (password === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newpassword: "required", // Set error message for newFirstName field
      }));
      return;
    }
    if (confirmPassword === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newConfirmPassword: "required", // Set error message for newFirstName field
      }));
      return;
    }
    registerApi();
  };

  const closeallhandle = () => {
    setEmailSection(false);
    setPasswordSection(false);
    setCreateaccountSection(false);
    setCreatePasswordSection(false);
    setForgotPasswordSection(false);
    setCreate_forgotPasswordSection(false);
    setOtpSection(false);
    setNewDeviceOtpSection(false);
  };
  const useAnotherAccountHandler = () => {
    closeallhandle();
    setAccountFound(false);
    setEmailSection(true);
  };
  const enterUserScreenhandle = () => {
    closeallhandle();
    setEmailSection(true);
  };
  const emailSectionhandle = () => {
    closeallhandle();
    setPasswordSection(true);
  };
  const create_forgotPasswordSection_handle = () => {
    closeallhandle();
    setCreate_forgotPasswordSection(true);
  };

  const createaccountSectionhandle = () => {
    closeallhandle();
    setCreateaccountSection(true);
    setCreateAccountOtpSection(true);
  };

  const forgotPasswordSectionhandle = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to send an OTP to reset your password?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Send OTP",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsResendDisabled(true);
        setCountdown(60);
        closeallhandle();
        setForgotPasswordSection(true);
        forgetpass_sendOtpApi();
      }
    });
  };
  const otpSectionhandle = () => {
    closeallhandle();

    setOtpSection(true);
  };
  const resendOtpHandler = () => {
    closeallhandle();
    setForgotPasswordSection(true);
  };

  const otpVerifyhandler = () => {
    if (realOtp === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newRealOtp: "required", // Set error message for newFirstName field
      }));
      return;
    }

    if (contry == "+91") {
      VerifyOtpApi();
    } else {
      onOTPVerify();
    }

    // VerifyOtpApi();

    // closeallhandle();

    // if (createAccountOtpSection) {
    //   setCreateAccountOtpSection(false);
    //   setEmailSection(true);
    // } else {
    //   setCreatePasswordSection(true);
    // }
  };
  const backTosSignInHandler = () => {
    closeallhandle();
    if (createAccountOtpSection) {
      closeallhandle();

      setOtpSection(true);
    } else {
      setEmailSection(true);
    }
  };

  const [phoneNumber, setPhoneNumber] = useState("");

  const handleOnChange = (value, country) => {
    console.log(value, country);
    // You can do something with the selected phone number and country code here
    setPhoneNumber(value);
    setNewUsername(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      newUsername: "",
    }));
  };
  const [profileUrl, setProfileUrl] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userSearchApi = async (e) => {
    
    /*
    if (location.latitude === null || location.longitude === null) {
      showLocationAccessDeniedAlert();
      return;
    }
    */
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://auth.remittpe.com/api/Auth/checkUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: loginPhone,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        const { status, message, data } = responseData;

        if (status === "00") {
          const { profile_url, user_name, user_email, user_phone, timestamp } =
            data;
          setProfileUrl(profile_url);
          setUserName(user_name);
          setUserEmail(user_email);
          setUserPhone(user_phone);
          setTimestamp(timestamp);

          // Proceed with further actions
          // toast.success("User Found");
          emailSectionhandle();
          setUserSearchResult(true);
        } else {
          toast.error("User Not Found.");
          setUserSearchResult(false);
          setErrors((prevErrors) => ({
            ...prevErrors,
            loginPhone: message || "User Not Found.",
          }));
        }
      } else {
        console.error("Login failed.");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const redirectFunction = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const callbackUrl = urlParams.get("callback");

    if (callbackUrl) {
      window.location.href = `${callbackUrl}?accessToken=${accessToken}`;
    } else {
      console.error("Callback URL not found in query parameters.");
    }
  };
  const loginApi = async (e) => {
    setIsLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const callbackUrl = urlParams.get("callback");
    const productId = urlParams.get("product_id");
    try {
      const response = await fetch(
        "https://auth.remittpe.com/api/Auth/identity",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            email: loginPhone,
            password: loginPass,
            latitude: location.latitude,
            longitude: location.longitude,
            device: "",
            country: contry,
            productId: productId,
            callback: callbackUrl,
          }),
        }
      );

      if (response.ok) {
        const { accessToken, userId } = await response.json();

        let tokens = getUniqueAccessTokensFromCookies();

        tokens = [...tokens, accessToken].slice(-5);

        document.cookie = `accessTokens=${encodeURIComponent(
          JSON.stringify(tokens)
        )}; path=/`;

        if (userId) {
          toast.success("Login successful.");
          setWrongCred(false);

          // redirectFunction();
          const urlParams = new URLSearchParams(window.location.search);
          const callbackUrl = urlParams.get("callback");

          if (callbackUrl) {
            window.location.href = `${callbackUrl}?accessToken=${accessToken}`;
          } else {
            console.error("Callback URL not found in query parameters.");
          }
        } else {
          setWrongCred(true);
        }
        console.log("Login successful.");
      } else {
        setWrongCred(true);
        console.error("Login failed.");
        toast.error("Login failed.");
      }
    } catch (error) {
      setWrongCred(true);
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const [confirmation, setConformation] = useState(false);
  const loginProfile = () => {
    setConformation(true);
  };

  const handleCancle = () => {
    setConformation(false);
  };

  const passwordValidation = async (e) => {
    e.preventDefault();

    if (loginPass.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        loginPass: "Please enter password.",
      }));
    } else {
      loginApi();
      setErrors((prevErrors) => ({ ...prevErrors, loginPass: "" }));
    }
  };

  const callLoginApi = async (e) => {
    e.preventDefault();

    if (!contry) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contry: "Please select Country ",
      }));
      return false;
    }

    if (contry === "+91") {
      if (validateInput()) {
        userSearchApi();
      }
    } else {
      if (validateInput()) {
        userSearchApi();
      }
    }
  };

  const validateInput = () => {
    if (!loginPhone) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        loginPhone: "Mobile number or email is required",
      }));
      return false;
    } else if (
      !/^\d{10}$/.test(loginPhone) &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(loginPhone)
    ) {
      // setError('Invalid mobile number or email format');
      setErrors((prevErrors) => ({
        ...prevErrors,
        loginPhone: "Invalid mobile number or email format",
      }));
      return false;
    }
    return true;
  };

  const [profiles, setProfiles] = useState([]);

  const VerifyToken = useCallback(
    async (accessToken) => {
      setIsLoading(true);
      try {
        console.log("accessToken:", accessToken);
        const payload = JSON.stringify({
          tokens: accessToken,
          product: "media",
        });

        const requestOptions = {
          method: "POST",
          headers: headers,
          body: payload,
        };
        const response = await fetch(
          "https://auth.remittpe.com/api/Auth/oAuth",
          requestOptions
        );
        console.log(response);
        if (response.ok) {
          const data = await response.json();

          if (data.status === "00") {
            // Assuming 'data' is the response and it has a 'data' field holding profiles
            setProfiles(data.data); // Update the profiles state with the profiles array
            // Other actions...
          }
          // console.log(data);
          setUserInfo(data);
          if (data.status === "00") {
            closeallhandle();
            setAccountFound(true);
          } else {
            setAccountFound(false);
            setEmailSection(true);
          }

          console.log("User info:", data);
        } else {
          setAccountFound(false);
          setEmailSection(true);
          console.error("Product verification failed.");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false); // End loading
      }
    },
    [accessToken]
  );

  const getUniqueAccessTokensFromCookies = () => {
    const cookies = document.cookie.split(";");
    let tokensString = cookies.find((cookie) =>
      cookie.trim().startsWith("accessTokens=")
    );

    if (tokensString) {
      try {
        const tokens = JSON.parse(
          decodeURIComponent(tokensString.split("=")[1])
        );
        return tokens;
      } catch (error) {
        console.error("Error parsing tokens from cookies:", error);
        return [];
      }
    }

    return [];
  };

  useEffect(() => {
    const tokens = getUniqueAccessTokensFromCookies();
    if (intent && intent === "signup") {
      setCreateaccountSection(true);
      setCreateAccountOtpSection(true);
      setEmailSection(false)
    } else {
      if (tokens.length > 0) {
        setAccessToken(tokens); // Update state with the latest unique tokens
        VerifyToken(tokens); // Pass tokens directly to VerifyToken
      }
    }
  }, []);

  const [userData, setUserData] = useState({
    username: "",
    password: "",
    firstName: "",
    lastName: "",
    dob: {
      day: "",
      month: "",
      year: "",
    },
    gender: "",
  });

  const handleGenderChange = (e) => {
    const gender = e.target.value;
    setUserData((prevUserData) => ({
      ...prevUserData,
      gender: gender,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      newGender: "",
    }));
  };

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (confirmPassword !== "") {
      setPasswordMatch(newPassword === confirmPassword);
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      newpassword: "",
    }));
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setPasswordMatch(password === newConfirmPassword);
    setErrors((prevErrors) => ({
      ...prevErrors,
      newConfirmPassword: "",
    }));
  };

  const VerifyOtpApi = async (e) => {
    setIsLoading(true);
    try {
      const dob = `${year}-${month}-${day}`;
      const response = await fetch(
        "https://auth.remittpe.com/api/Auth/verify",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            username: newUsername || newEmail,
            step: "2",

            otp: realOtp,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.status === "00") {
          // swal("Success!", responseData.message, "success");
          toast.success(responseData.message);

          closeallhandle();
          enterUserScreenhandle();
          setIsResendDisabled(true);
          setCountdown(60);
        } else {
          // swal("Error!", responseData.message, "error");
          toast.error(responseData.message);

          setWrongCred(true);
          console.error("Registration failed.");
        }
      } else {
        setWrongCred(true);
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      setWrongCred(true);
      console.error("Error:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const resendOtpApi = async (e) => {
    setIsLoading(true);
    try {
      const dob = `${year}-${month}-${day}`;
      const response = await fetch(
        "https://auth.remittpe.com/api/Auth/verify",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            username: newUsername || newEmail,
            step: "1",

            otp: "",
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.status === "00") {
          toast.success(responseData.message);

          // swal("Success!", responseData.message, "success");
          // closeallhandle();
          // enterUserScreenhandle();
          setIsResendDisabled(true);
          setCountdown(60);
        } else {
          // swal("Error!", responseData.message, "error");
          toast.error(responseData.message);

          setWrongCred(true);
          console.error("Registration failed.");
        }
      } else {
        setWrongCred(true);
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      setWrongCred(true);
      console.error("Error:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const registerApi = async (e) => {
    setIsLoading(true);
    try {
      if (newUsername) {
        setUserNameAPI(newUsername);
      } else {
        setUserNameAPI(newEmail);
      }
      const urlParams = new URLSearchParams(window.location.search);
      const callbackUrl = urlParams.get("callback");
      const productId = urlParams.get("product_id");

      const dob = `${year}-${month}-${day}`;
      const response = await fetch(
        "https://auth.remittpe.com/api/Auth/register2",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            username: newUsername || newEmail,
            password: password,
            firstName: newFirstName,
            lastName: newLastName,
            dob: dob,
            gender: userData.gender,
            country: contry,
            latitude: location.latitude,
            longitude: location.longitude,
            device: "",
            callback: callbackUrl,
            productId: productId,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.status === "00") {
          // swal("Success!", , "success");
          toast.success(responseData.message);
          closeallhandle();
          // enterUserScreenhandle();
          if (contry == "+91" || contry == "91") {
            otpSectionhandle();
            setIsResendDisabled(true);
            setCountdown(60);
          } else {
            onSignup(() => {
              otpSectionhandle();
              setIsResendDisabled(true);
              setCountdown(60);
            });
          }
        } else {
          // swal("Error!", responseData.message, "error");
          toast.error(responseData.message);
          setWrongCred(true);
          console.error("Registration failed.");
        }
      } else {
        setWrongCred(true);
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      setWrongCred(true);
      console.error("Error:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const monthRef = useRef(null);
  const yearRef = useRef(null);

  const handleDayChange = (e) => {
    const val = e.target.value;
    if (/^\d{0,2}$/.test(val)) {
      setDay(val);
      if (val.length === 2) {
        monthRef.current.focus(); // Move focus to month input
      }
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      newDobday: "",
    }));
  };

  const handleMonthChange = (e) => {
    const val = e.target.value;
    if (/^\d{0,2}$/.test(val)) {
      setMonth(val);
      if (val.length === 2) {
        yearRef.current.focus(); // Move focus to year input
      }
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      newDobmonths: "",
    }));
  };

  const handleYearChange = (e) => {
    const val = e.target.value;
    if (/^\d{0,4}$/.test(val)) {
      setYear(val);
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      newDobyear: "",
    }));
  };

  const onBlurPadZero = (e, setter) => {
    let val = e.target.value;
    if (val.length === 1) {
      val = `0${val}`;
      setter(val);
    }
  };
  // const showLocationAccessDeniedAlert = () => {
  //   Swal.fire({
  //     icon: 'error',
  //     title: 'Location Access Denied',
  //     html: `
  //       <p>Without location access, you cannot proceed with login.</p>
  //     `,
  //     showCancelButton: true,
  //     confirmButtonText: 'OK'
  //   });
  // };
  const showLocationAccessDeniedAlert = () => {
    toast.error(
      "Please enable location access and refresh the page to log in. Without it, you cannot proceed.",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const [selectedProfile, setSelectedProfile] = useState(null);
  const handleProfileClick = (profile) => {
    // if (location.latitude === null || location.longitude === null) {
    //   showLocationAccessDeniedAlert();
    //   return;
    // }
    setSelectedProfile(profile);
    loginProfile();
  };

  const forgetpass_sendOtpApi = async (e) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://auth.remittpe.com/api/Auth/forgetPassword",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            username: loginPhone,
            password: "NA",
            step: "1",
            otp: "NA",
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.status === "00") {
          // swal("Success!", responseData.message, "success");
          toast.success(responseData.message);

          // closeallhandle();
          // enterUserScreenhandle();
        } else {
          // swal("Error!", responseData.message, "error");
          toast.error(responseData.message);

          setWrongCred(true);
          console.error("Registration failed.");
        }
      } else {
        setWrongCred(true);
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      setWrongCred(true);
      console.error("Error:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const forgetpass_VerifyOtpApi = async (e) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://auth.remittpe.com/api/Auth/forgetPassword",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            username: loginPhone,
            password: "NA",
            step: "2",
            otp: forgetPassword_RealOtp,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.status === "00") {
          // swal("Success!", responseData.message, "success");
          toast.success(responseData.message);

          closeallhandle();
          // enterUserScreenhandle();
          create_forgotPasswordSection_handle();
        } else {
          // swal("Error!", responseData.message, "error");
          toast.error(responseData.message);

          setWrongCred(true);
          console.error("Registration failed.");
        }
      } else {
        setWrongCred(true);
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      setWrongCred(true);
      console.error("Error:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const createNewPass_ForgetPasswordAPi = async (e) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://auth.remittpe.com/api/Auth/forgetPassword",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            username: loginPhone,
            password: password,
            step: "3",
            otp: forgetPassword_RealOtp,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.status === "00") {
          // swal("Success!", responseData.message, "success");
          toast.success(responseData.message);

          closeallhandle();
          // enterUserScreenhandle();
          // create_forgotPasswordSection_handle();
          emailSectionhandle();
        } else {
          // swal("Error!", responseData.message, "error");
          toast.error(responseData.message);

          setWrongCred(true);
          console.error("Registration failed.");
        }
      } else {
        setWrongCred(true);
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      setWrongCred(true);
      console.error("Error:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const isPhoneNumber = (input) => {
    return /^\d+$/.test(input); // returns true if input is entirely numeric
  };

  // Function to format phone number or email
  const formatPhoneNumber = (contact) => {
    if (isPhoneNumber(contact)) {
      // Mask all but the last four digits of the phone number
      return contact.slice(0, -4).replace(/./g, "*") + contact.slice(-4);
    } else {
      // Mask part of the email but keep the domain
      const atIndex = contact.indexOf("@");
      const maskedEmail =
        contact.slice(0, atIndex).replace(/.(?=.{4})/g, "*") +
        contact.slice(atIndex);
      return maskedEmail;
    }
  };

  const [countdown, setCountdown] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  useEffect(() => {
    let interval;
    if (isResendDisabled) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(interval);
            setIsResendDisabled(false);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isResendDisabled]);
  const [productInfo, setProductInfo] = useState({
    id: null,
    product_title: null,
    product_description: null,
    logo_url: null,
    indate: null,
    status: null,
    product_url: null,
  });
  const productInfoApi = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization:
        "Basic QlVNUFBZX1NTT19TZ0xqdkFSSjVMYlg6dEdoQWxQSEVreDFNY0Jua3c0M3ZYVVhzNDRpNzQ0alFiYmpVMU9ESEI1U0lSdFNERllhWUYxeWVHRkpCWWxacQ==",
    };
    const urlParams = new URLSearchParams(window.location.search);
    const callbackUrl = urlParams.get("callback");
    const productId = urlParams.get("product_id");

    const data = {
      callback: callbackUrl,
      productId: productId,
    };

    try {
      const response = await axios.post(
        "https://auth.remittpe.com/api/Auth/getProductInfo",
        data,
        { headers }
      );
      setProductInfo(response.data.data);
    } catch (error) {
      console.error("Error fetching product info:", error);
    }
  };

  useEffect(() => {
    productInfoApi();
  }, []);
  const isEightChars = password.length >= 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  const getItemClass = (condition) =>
    condition ? "text-success" : "gray-colour";

  // NEW OTP FLOW
  const [loading, setLoading] = useState(false);

  console.log(auth);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            console.log(response);
            onSignInSubmit();
          },
        }
      );
      // onSignup(callbackFunction);
    }
  }

  function onSignup(callbackFunction) {
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    console.log(appVerifier);

    const formatPh = contry + newUsername;
    console.log(formatPh);

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        toast.success("OTP sended successfully!");
        callbackFunction();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(realOtp)
      .then(async (res) => {
        toast.success("OTP Verified Successfully!");

        closeallhandle();
        enterUserScreenhandle();
        setIsResendDisabled(true);
        setCountdown(60);
      })
      .catch((err) => {
        // swal("Error!", responseData.message, "error");
        toast.error("Registration failed.");

        setWrongCred(true);
        console.error("Registration failed.");
      });
  }

  /// END

  return (
    <>
      <ToastContainer position="top-center" />

      <div className="parent-box ">
        <div id="recaptcha-container"></div>
        {/* {location && (
        <div>
          <h3>Location Details:</h3>
          <p>Latitude: {location.latitude}</p>
          <p>Longitude: {location.longitude}</p>
        </div>
      )} */}

        <div className="bg-white-css sign-in-box shadow p-0 m-0">
          {isLoading && <div className="loading-line "></div>}
          {!isLoading && <div className="loading-line-stop"></div>}

          <div className="p-4" style={{ height: "90%" }}>
            <MultipleProductSection productId={productIdGlobal} />
            {/* Profile Found in Cookies  */}
            {accountFound && (
              <>
                <div className="mt-2">
                  <img
                    src={bumppylogo}
                    alt="logo"
                    className="vertical-middle-css "
                    height={20}
                    width={20}
                  />
                  <span className="vertical-middle-css ps-2 font-size-second-normal">
                    {" "}
                    Sign in with Bumppy{" "}
                  </span>

                  {/* Initial */}
                  {!confirmation && (
                    <>
                      <div className=" ">
                        <img
                          src={productInfo.logo_url}
                          className="my-3"
                          alt=""
                          height={50}
                          width={50}
                        />
                        <p className="choose-account font-size-highest">
                          Choose an account
                        </p>
                        <p className="continue-text">
                          {" "}
                          to continue to{" "}
                          <span>{productInfo.product_title}</span>
                        </p>
                      </div>

                      <div className="profiles-container">
                        {profiles.map((profile, index) => (
                          <div key={index}>
                            <div
                              className="profile-box row ps-3 py-2"
                              onClick={() => handleProfileClick(profile)}
                            >
                              <div className="col-1 d-flex align-items-center ">
                                <img
                                  src={profile.profile_url}
                                  className="vertical-middle-css"
                                  alt=""
                                  height={50}
                                  style={{ borderRadius: "50px" }}
                                />
                              </div>
                              <div className="col-11 row cursor-pointer button-10">
                                <div className="col-12 mx-4 d-flex justify-content-start font-size-normal">
                                  {profile.user_name}
                                </div>
                                <div className="col-12 mx-4 d-flex justify-content-start font-size-normal">
                                  {profile.user_email}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="profile-box-another row col-12 m-0 py-2 ">
                        <div className="col-1 d-flex align-items-center">
                          <img
                            src={Profile}
                            className="vertical-middle-css"
                            height={50}
                            alt=""
                          />
                        </div>
                        <div className="col-11 d-flex align-items-center cursor-pointer button-10">
                          <div
                            onClick={useAnotherAccountHandler}
                            className="col-12 mx-4 d-flex justify-content-start font-size-normal"
                          >
                            Use another account
                          </div>
                        </div>
                      </div>
                      {/* <div className="font-size-normal-2">
                      If you're comfortable with Google sharing your name, email address, language preference, and profile picture with Bumppy, you can proceed by clicking "Continue." 
                      </div> */}
                    </>
                  )}

                  {/* continue section*/}

                  {confirmation && (
                    <>
                      <div className=" py-5">
                        <p className="choose-account font-size-highest">
                          Sign in to <span>{productInfo.product_title}</span>
                        </p>
                        <p className="continue-text m-0">
                          {" "}
                          <img
                            src={selectedProfile.profile_url}
                            className="vertical-middle-css me-2 "
                            alt=""
                            height={20}
                            style={{ borderRadius: "10px" }}
                          />{" "}
                          {selectedProfile.user_email}
                        </p>
                      </div>

                      <div className=" font-size-normal-2 ">
                        <p className="m-0">
                          If you're comfortable with sharing your name, email
                          address, language preference, and profile picture with{" "}
                          <span>{productInfo.product_title}</span>, you can
                          proceed by clicking "Continue."
                          <span className="font-size-policy cursor-pointer ">
                            {" "}
                            privacy policy{" "}
                          </span>{" "}
                          and{" "}
                          <span className="font-size-policy text-primary cursor-pointer">
                            {" "}
                            terms of Service
                          </span>
                        </p>
                      </div>

                      <div className="py-4">
                        <button
                          type="submit"
                          className="from-border w-100 p-2 mb-2 bg-yellow-css cursor-pointer button-10 font-size-normal font-bold-css"
                          onClick={() => {
                            toast.success("Login successful.");

                            const urlParams = new URLSearchParams(
                              window.location.search
                            );
                            const callbackUrl = urlParams.get("callback");

                            if (callbackUrl) {
                              window.location.href = `${callbackUrl}?accessToken=${selectedProfile.token}`;
                            } else {
                              console.error(
                                "Callback URL not found in query parameters."
                              );
                            }
                          }}
                        >
                          Continue
                        </button>
                        <div
                          className="from-border p-2 cursor-pointer button-10 font-size-normal font-bold-css"
                          onClick={handleCancle}
                        >
                          Cancel
                        </div>
                      </div>

                      {/* <div className=" d-flex pb-5 ">
                        <div
                          className=" flex-grow-1 p-3 me-2 button-border cursor-pointer button-10"
                          onClick={handleCancle}
                        >
                          Cancel
                        </div>
                        <div
                          className=" flex-grow-1 button-border  p-3  ms-2 cursor-pointer button-10"
                          onClick={() => {
                          
                            toast.success("Login successful.");

                            const urlParams = new URLSearchParams(
                              window.location.search
                            );
                            const callbackUrl = urlParams.get("callback");

                            if (callbackUrl) {
                              window.location.href = `${callbackUrl}?accessToken=${selectedProfile.token}`;
                            } else {
                              console.error(
                                "Callback URL not found in query parameters."
                              );
                            }
                          }}
                        >
                          Continue
                        </div>
                      </div> */}
                    </>
                  )}
                </div>
              </>
            )}

            {/* Main Login Username */}
            {emailSection && (
              <>
                <div className=" pb-4">
                  <img
                    src={productInfo.logo_url}
                    className="mt-3"
                    alt=""
                    height={50}
                    width={50}
                  />
                  <p className="choose-account font-size-highest m-3 ">
                    Sign in
                  </p>
                  <p className=" continue-text font-size-normal m-0">
                    {" "}
                    Welcome to <span>{productInfo.product_title}</span>
                  </p>
                  {/* <p>Please log in to access your account and explore our exciting features.
                  </p> */}
                </div>

                <Form
                  className="pb-2 d-flex justify-content-center gap-2"
                  onSubmit={callLoginApi}
                >
                  <span className="input-group-text bg-transparent border-right-0 no-glow">
                    <select
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      style={{
                        // padding: "4px",
                        border: "none",
                        outline: "none",
                      }}
                    >
                      {["+91", "+1"].map((en) => (
                        <option>{en}</option>
                      ))}
                    </select>
                  </span>
                  <FormControl
                    type="text"
                    className={`p-2 ${
                      errors.loginPhone === "" ? "" : "is-invalid"
                    }`}
                    placeholder="Enter Mobile Number"
                    value={loginPhone}
                    onChange={(e) => {
                      setLoginPhone(e.target.value); // Limit input to 10 characters
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        loginPhone: "",
                      }));
                    }}
                  />
                </Form>
                {errors.loginPhone && (
                  <p
                    className="text-danger text-start m-0 pb-2"
                    style={{ fontSize: "14px" }}
                  >
                    {errors.loginPhone}
                  </p>
                )}
                <div className="pb-4">
                  <button
                    type="submit"
                    className="from-border w-100 p-2 mb-2 bg-yellow-css cursor-pointer button-10 font-size-normal font-bold-css"
                    onClick={callLoginApi}
                  >
                    Continue
                  </button>
                  <div
                    className="from-border p-2 cursor-pointer button-10 font-size-normal font-bold-css"
                    onClick={createaccountSectionhandle}
                  >
                    Create account
                  </div>
                  <div className="text-center pt-4 font-size-normal-2">
                  Anlook, Trawlo, Chaafo, Bumpy Payments, and Edu Rally are trademarks of Bumppy. 
                  Terms, features, pricing, and services are subject to change without notice.
                  </div>
                </div>
              </>
            )}

            {/* Main Login Password Enter */}
            {passwordSection && (
              <>
                <div className="py-4">
                  <p className="choose-account font-size-highest">
                    Hello {userName}
                  </p>
                  <p className="continue-text">
                    {" "}
                    <img
                      src={profileUrl}
                      className="vertical-middle-css me-2 "
                      alt=""
                      height={20}
                      style={{ borderRadius: "10px" }}
                    />{" "}
                    {userEmail}
                  </p>
                </div>
                <Form className="py-0" onSubmit={passwordValidation}>
                  <FormControl
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your Password"
                    className="p-2"
                    value={loginPass}
                    onChange={(e) => {
                      setLoginPass(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        loginPass: "",
                      }));
                    }}
                  />
                  <div className="d-flex py-2">
                    <Form.Check
                      type="checkbox"
                      className=""
                      id="custom-checkbox"
                      onChange={() => setShowPassword(!showPassword)}
                    />
                    <span className="ps-2 p-0">Show password</span>
                  </div>
                </Form>
                {errors.loginPass && (
                  <p className="text-danger text-start  m-0">
                    *{errors.loginPass}
                  </p>
                )}
                {wrongCred && (
                  <p className="text-danger text-start py-1 m-0">
                    *Login Failed
                  </p>
                )}

                <div className="pt-2 pb-4">
                  <button
                    className="from-border w-100  bg-yellow-css cursor-pointer button-10 font-size-normal font-bold-css"
                    onClick={passwordValidation}
                  >
                    Continue
                  </button>
                  <div
                    className="from-border my-2 cursor-pointer button-10 font-size-normal font-bold-css"
                    onClick={forgotPasswordSectionhandle}
                  >
                    Forgot password?
                  </div>
                  <div
                    className="from-border   cursor-pointer button-10 font-size-normal font-bold-css"
                    onClick={enterUserScreenhandle}
                  >
                    Back
                  </div>
                </div>
              </>
            )}

            {/* Enter Otp for New Device Login  */}
            {newDeviceOtpSection && (
              <>
                <div className="py-4">
                  <p className="choose-account font-size-highest">
                    OTP Verification
                  </p>
                  <p className="continue-text font-size-normal">
                    Please enter the verification code sent to your phone{" "}
                    {formatPhoneNumber(loginPhone)} to confirm your identity.
                  </p>
                </div>

                <Form
                  className="pb-2 "
                  onSubmit={(e) => {
                    e.preventDefault();
                    forgetpass_VerifyOtpApi();
                  }}
                >
                  <FormControl
                    type="text"
                    value={forgetPassword_RealOtp}
                    placeholder="Enter OTP"
                    className={`p-2 ${
                      errors.newRealOtp === "" ? "" : "is-invalid"
                    }`}
                    onChange={(e) => {
                      setForgetPassword_RealOtp(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        newRealOtp: "",
                      }));
                    }}
                  />
                </Form>

                <div className="pb-4">
                  <div
                    className="from-border p-2 mb-2 bg-yellow-css cursor-pointer button-10 font-size-normal font-bold-css"
                    onClick={forgetpass_VerifyOtpApi}
                  >
                    Verify OTP
                  </div>
                  <div
                    className={`from-border p-2 cursor-pointer button-10 font-size-normal font-bold-css ${
                      isResendDisabled ? "disabled" : ""
                    }`}
                    onClick={
                      isResendDisabled ? null : forgotPasswordSectionhandle
                    }
                  >
                    {isResendDisabled
                      ? `Resend OTP (${countdown}s)`
                      : "Resend OTP"}
                  </div>
                  <div
                    className="from-border mt-2 p-2 cursor-pointer button-10 font-size-normal font-bold-css"
                    onClick={enterUserScreenhandle}
                  >
                    Login
                  </div>
                </div>
              </>
            )}

            {/* Create Account Main */}
            {createaccountSection && (
              <>
                <div className=" py-2">
                  <p className="choose-account font-size-highest">
                    Create {productIdGlobal=="1"?"An Anlook" :"A Bumppy"} Account
                  </p>
                </div>

                <Form
                  className=" m-lg-2 "
                  onSubmit={createPasswordSectionhandle}
                >
                  <div className=" d-flex  m-0 my-2 ">
                    <FormControl
                      type="text"
                      placeholder={
                        !errors.newFirstName ? "First Name" : " First Name"
                      }
                      className={`p-2 ${
                        errors.newFirstName === "" ? "" : "is-invalid"
                      }`}
                      value={newFirstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          newFirstName: "", // Set error message for newFirstName field
                        }));
                      }}
                    />
                    <FormControl
                      type="text"
                      placeholder={
                        !errors.newFirstName ? "Last Name" : "Last Name"
                      }
                      className={`p- ms-2 ${
                        errors.newLastName === "" ? "" : "is-invalid"
                      }`}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          newLastName: "",
                        }));
                      }}
                    />
                  </div>
                  {/* <PhoneInput
                    country={"in"}
                    className={`m-lg-2 m-0 my-2 ${
                      errors.newUsername === "" ? "" : "is-invalid"
                    }`}
                    value={phoneNumber}
                    onChange={handleOnChange}
                    inputProps={{
                      name: "phone",
                      required: true,

                      style: {
                        width: "100%",
                        paddingTop: "1.25rem",
                        paddingBottom: "1.25rem",
                      },
                    }}
                  /> */}
                  {/* {errors.newUsername !== "" && (
                    <>
                      <p className="invalid-feedback py-0 my-0  text-start">
                        *{errors.newUsername}
                      </p>
                    </>
                  )} */}

                  <div className="d-flex justify-content-center gap-2">
                    <span className="input-group-text bg-transparent border-right-0 no-glow">
                      <select
                        onChange={(e) => {
                          setCountry(e.target.value);
                        }}
                        style={{
                          // padding: "4px",
                          border: "none",
                          outline: "none",
                        }}
                      >
                        {["+91", "+1"].map((en) => (
                          <option>{en}</option>
                        ))}
                      </select>
                    </span>
                    <FormControl
                      type="tel"
                      className={`p-2   ${
                        errors.newUsername === "" ? "" : "is-invalid"
                      }`}
                      maxLength={10}
                      placeholder="Enter Mobile Number"
                      value={newUsername}
                      onChange={(e) => {
                        const inputValue = e.target.value.replace(/\D/g, "");
                        setNewUsername(inputValue.slice(0, 10));
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          newUsername: "",
                        }));
                      }}
                    />
                  </div>

                  <FormControl
                    type="email"
                    placeholder="Enter e-mail"
                    className={`p-2  m-0 my-2 ${
                      errors.newEmail === "" ? "" : "is-invalid"
                    }`}
                    value={newEmail}
                    onChange={(e) => {
                      setNewEmail(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        newEmail: "",
                      }));
                    }}
                  />

                  <div className=" d-flex  m-0 my-2 w-100">
                    <FormControl
                      type="text"
                      placeholder="DD"
                      className={`p-2 ${
                        errors.newDobday === "" ? "" : "is-invalid"
                      }`}
                      value={day}
                      onChange={handleDayChange}
                      onBlur={(e) => onBlurPadZero(e, setDay)}
                    />
                    <FormControl
                      ref={monthRef}
                      type="text"
                      placeholder="MM"
                      className={`p-2  mx-2 ${
                        errors.newDobmonths === "" ? "" : "is-invalid"
                      }`}
                      value={month}
                      onChange={handleMonthChange}
                      onBlur={(e) => onBlurPadZero(e, setMonth)}
                    />
                    <FormControl
                      ref={yearRef}
                      type="text"
                      placeholder="YYYY"
                      className={`p-2 ${
                        errors.newDobyear === "" ? "" : "is-invalid"
                      }`}
                      value={year}
                      onChange={handleYearChange}
                      // Optionally, ensure the year is four digits when focus is lost
                      onBlur={(e) => onBlurPadZero(e, setYear)}
                    />
                  </div>
                  <Form.Select
                    name="gender"
                    aria-label="Select Gender"
                    value={userData.gender}
                    onChange={handleGenderChange}
                    className={`p-2  m-0 my-2 ${
                      errors.newGender === "" ? "" : "is-invalid"
                    }`}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="others">Others</option>
                  </Form.Select>
                </Form>

                <div className="  ">
                  <div
                    className="from-border p-2 bg-yellow-css cursor-pointer button-10 font-size-normal font-bold-css"
                    onClick={createPasswordSectionhandle}
                  >
                    Continue
                  </div>
                  <div
                    className="from-border p-2 my-2 cursor-pointer button-10 font-size-normal font-bold-css"
                    onClick={enterUserScreenhandle}
                  >
                    Login
                  </div>
                </div>
              </>
            )}

            {/* Create Account New Password */}
            {createPasswordSection && (
              <>
                <div className="py-3 ">
                  <p className="choose-account font-size-highest">
                    Create a Strong Password
                  </p>
                </div>

                <div>
                  <Form className="" onSubmit={handleRegisterPasswordSection}>
                    <FormControl
                      type="password"
                      placeholder="Password"
                      className={`p-2  m-0 my-2 ${
                        errors.newpassword === "" ? "" : "is-invalid"
                      }`}
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <FormControl
                      type={showPassword ? "text" : "password"}
                      placeholder="Confirm password"
                      className={`p-2  m-0 my-2 ${
                        errors.newConfirmPassword === "" ? "" : "is-invalid"
                      } ${passwordMatch ? "" : "is-invalid"}`}
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    {!passwordMatch && (
                      <div className="invalid-feedback">
                        Passwords do not match.
                      </div>
                    )}
                    <div className="d-flex  m-0 mt-2 ">
                      <Form.Check
                        type="checkbox"
                        className="mb-1"
                        id="custom-checkbox"
                        onChange={() => setShowPassword(!showPassword)}
                      />
                      <span className="ps-2 ">Show password</span>
                    </div>
                  </Form>
                  <div className="  d-flex justify-content-start">
                    <ul className=" pb-0 mb-2 ">
                      <li className="text-start gray-colour">
                        At least 8 characters
                      </li>
                      <li className="text-start gray-colour">
                        At least one uppercase letter
                      </li>
                      <li className="text-start gray-colour">
                        At least one number
                      </li>
                      <li className="text-start gray-colour">
                        At least one special character
                      </li>
                    </ul>
                  </div>
                  <div className="  w-100">
                    <div
                      className="from-border  bg-yellow-css cursor-pointer button-10 font-size-normal font-bold-css"
                      onClick={handleRegisterPasswordSection}
                    >
                      Continue
                    </div>
                    <div
                      className="from-border mt-2 cursor-pointer button-10 font-size-normal font-bold-css"
                      onClick={enterUserScreenhandle}
                    >
                      Login
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Forgot Password Write OTP with Timmer  */}
            {forgotPasswordSection && (
              <>
                <div className="py-4">
                  <p className="choose-account font-size-highest">
                    OTP Verification
                  </p>
                  <p className="continue-text font-size-normal">
                    Please enter the verification code sent to your phone{" "}
                    {formatPhoneNumber(loginPhone)} to confirm your identity.
                  </p>
                </div>

                <Form
                  className="pb-2 "
                  onSubmit={(e) => {
                    e.preventDefault();
                    forgetpass_VerifyOtpApi();
                  }}
                >
                  <FormControl
                    type="text"
                    value={forgetPassword_RealOtp}
                    placeholder="Enter OTP"
                    className={`p-2 ${
                      errors.newRealOtp === "" ? "" : "is-invalid"
                    }`}
                    onChange={(e) => {
                      setForgetPassword_RealOtp(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        newRealOtp: "",
                      }));
                    }}
                  />
                </Form>

                <div className="pb-4">
                  <div
                    className="from-border p-2 mb-2 bg-yellow-css cursor-pointer button-10 font-size-normal font-bold-css"
                    onClick={forgetpass_VerifyOtpApi}
                  >
                    Verify OTP
                  </div>
                  <div
                    className={`from-border p-2 cursor-pointer button-10 font-size-normal font-bold-css ${
                      isResendDisabled ? "disabled" : ""
                    }`}
                    onClick={
                      isResendDisabled ? null : forgotPasswordSectionhandle
                    }
                  >
                    {isResendDisabled
                      ? `Resend OTP (${countdown}s)`
                      : "Resend OTP"}
                  </div>
                  <div
                    className="from-border mt-2 p-2 cursor-pointer button-10 font-size-normal font-bold-css"
                    onClick={enterUserScreenhandle}
                  >
                    Login
                  </div>
                </div>
              </>
            )}
            {/* New Password in Forgot Password */}
            {create_forgotPasswordSection && (
              <>
                <div className="py-4">
                  <p className="choose-account font-size-highest">
                    Create a Strong Password
                  </p>
                </div>

                <div>
                  <Form
                    className=" "
                    onSubmit={createNewPass_ForgetPasswordAPi}
                  >
                    <FormControl
                      type="password"
                      placeholder="Password"
                      className={`p-2  m-0 my-2 ${
                        errors.newpassword === "" ? "" : "is-invalid"
                      }`}
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <FormControl
                      type={showPassword3 ? "text" : "password"}
                      placeholder="Confirm password"
                      className={`p-2  m-0 my-2 ${
                        errors.newConfirmPassword === "" ? "" : "is-invalid"
                      } ${passwordMatch ? "" : "is-invalid"}`}
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    {!passwordMatch && (
                      <div className="invalid-feedback text-start">
                        *Passwords do not match.
                      </div>
                    )}
                    <div className="d-flex  m-0  mb-2">
                      <Form.Check
                        type="checkbox"
                        className=""
                        id="custom-checkbox"
                        onChange={() => setShowPassword3(!showPassword3)}
                      />
                      <span className="ps-2 ">Show password</span>
                    </div>
                  </Form>

                  <div className="d-flex justify-content-start mb-4">
                    <div
                      className={`text-start ${getItemClass(
                        isEightChars &&
                          hasUppercase &&
                          hasNumber &&
                          hasSpecialChar
                      )}`}
                    >
                      Password must be at least 8 characters long, contain at
                      least one uppercase letter, one number, and one special
                      character.
                    </div>
                  </div>
                  <div className="  m-0 py-2 w-100">
                    <div
                      className="from-border p-2 mb-2 bg-yellow-css cursor-pointer button-10 font-size-normal font-bold-css"
                      onClick={createNewPass_ForgetPasswordAPi}
                    >
                      Continue
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Otp Varify - Create Account */}
            {otpSection && (
              <>
                <div className="py-4">
                  <p className="choose-account font-size-highest">
                    OTP Verification
                  </p>
                  <p className=" font-size-normal">
                    Please enter the verification code sent to your
                    {newUsername ? " mobile " : " email "}
                    {formatPhoneNumber(newUsername || newEmail)} to confirm your
                    identity.
                  </p>
                </div>

                <Form className="pb-2" onSubmit={otpVerifyhandler}>
                  <FormControl
                    type="text"
                    value={realOtp}
                    placeholder="Enter OTP"
                    className={`p-2 ${
                      errors.newRealOtp === "" ? "" : "is-invalid"
                    }`}
                    onChange={(e) => {
                      setRealOtp(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        newRealOtp: "",
                      }));
                    }}
                  />
                </Form>

                <div className="pb-4">
                  <div
                    className="from-border p-2 mb-2 bg-yellow-css cursor-pointer button-10 font-size-normal font-bold-css"
                    onClick={otpVerifyhandler}
                  >
                    Verify OTP
                  </div>
                  {/* <div
                    className="from-border p-2 cursor-pointer button-10 font-size-normal font-bold-css"
                    onClick={resendOtpApi}
                  >
                    Resend OTP
                  </div> */}
                  <div
                    className={`from-border p-2 cursor-pointer button-10 font-size-normal font-bold-css ${
                      isResendDisabled ? "disabled" : ""
                    }`}
                    onClick={isResendDisabled ? null : resendOtpApi}
                  >
                    {isResendDisabled
                      ? `Resend OTP (${countdown}s)`
                      : "Resend OTP"}
                  </div>
                  <div
                    className="from-border p-2 my-2 cursor-pointer button-10 font-size-normal font-bold-css"
                    onClick={enterUserScreenhandle}
                  >
                    Login
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="d-flex  justify-content-end align-items-end  footer px-4">
            <div className=" p-2 flex-grow-1 single-line text-start font-size-small">
              English
            </div>

            <div className="p-2 single-line font-size-small">Help</div>

            <div className="p-2 single-line font-size-small">
              Privacy Policy
            </div>

            <div className="p-2 single-line font-size-small">
              Terms & Conditions
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MultipleProductSection = ({ productId }) => {
  return (
    <div className="pb-3">
      <div className=" pb-3">
        <img src={logo} height={25} alt="logo" />
      </div>

      <div className="d-flex justify-content-between">
        <div className="font-size-small vertical-middle-css">
          <img
            src={travel}
            height={15}
            width={15}
            className="me-1 vertical-middle-css"
            alt="img"
          />
          Travel
        </div>
        <div className="font-size-small vertical-middle-css">
          <img
            src={food}
            height={15}
            width={15}
            className="me-1 vertical-middle-css"
            alt="img"
          />
          Food
        </div>
        <div className="font-size-small vertical-middle-css ">
          <img
            src={payment}
            height={15}
            width={15}
            className="me-1 vertical-middle-css"
            alt="img"
          />
          Payments
        </div>
        <div className="font-size-small vertical-middle-css">
          <img
            src={ecom}
            height={15}
            width={15}
            className="me-1 vertical-middle-css"
            alt="img"
          />
          {productId == "1" ? <strong>Anlook</strong> : "Anlook"}
        </div>
        <div className="font-size-small vertical-middle-css">
          <img
            src={edtech}
            height={15}
            width={15}
            className="me-1 vertical-middle-css"
            alt="img"
          />
          ED-Tech
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
