import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyD1Pp-1klaWfelS65pqtbiIKaSXwQH7-U4",
  authDomain: "singlesignon-2fa84.firebaseapp.com",
  projectId: "singlesignon-2fa84",
  storageBucket: "singlesignon-2fa84.firebasestorage.app",
  messagingSenderId: "115955938882",
  appId: "1:115955938882:web:5922fad1623f9507171fdb"
}

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

// auth.settings.appVerificationDisabledForTesting = true;
